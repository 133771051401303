import { graphql, useStaticQuery } from 'gatsby';

const useLegal = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPage(filter: { template: { templateName: { eq: "Legal" } } }) {
        nodes {
          uri
          title
          content
        }
      }
    }
  `);

  return data?.allWpPage;
};

export default useLegal;
