import { graphql, Link } from 'gatsby';
import * as React from 'react';
import Layout from '~/components/global/Layout';
import { navigate } from 'gatsby';

import FlexibleContent from '~/components/global/FlexibleContent';
import { WpPage_Page_FlexibleContent } from '~/_generated/types';
import { Icon, IconType } from '../elements/Icon';
import useLegal from '~/hooks/useLegal';
import moment from 'moment';
import { SeoContainer } from '../global/SeoContainer';

export default function legalPage({
  data: { wpPage, wp },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  const { nodes } = useLegal();

  const handleNavigate = (e) => {
    navigate(e);
  };

  return (
    <Layout wp={wp}>
      <SeoContainer post={wpPage} />
      <div className="relative p-2 mx-auto md:px-5 md:py-4 ">
        <div className="rounded-md default-blue-gradient">
          <div className="noisy">
            <div className="container relative flex items-center pt-[6rem] pb-8 md:pt-[10rem] md:pb-[9.5rem]">
              <div className="space-y-[8px]">
                <h6 className="text-h6">Legal</h6>
                <h1 className="text-h1">{wpPage?.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FlexibleContent content={wpPage?.page?.flexibleContent} /> */}
      <section className=" mt-8 mb-12 md:my-12 lg:mt-[97px] lg:mb-[200px]">
        <div className="container ">
          <div className="flex flex-wrap justify-center md:flex-nowrap">
            <div className="relative md:w-3/12">
              <div className="sticky top-[7rem] hidden flex-row flex-wrap items-start w-full mb-12 md:flex md:flex-col w-[100%] md:mb-0">
                {nodes?.map((node, i, arr) => (
                  <Link
                    key={`legalNavItem${i}`}
                    to={node?.uri}
                    className={`${
                      i === arr.length - 1 ? '' : 'mb-3 mr-3 md:mr-0'
                    } font-bold font-heading text-18 opacity-[0.18] hover:opacity-100 transition-opacity duration-200 ease-in-out `}
                    activeClassName="!opacity-100"
                  >
                    {node?.title}
                  </Link>
                ))}
              </div>
            </div>

            <form
              className="relative w-full mb-10 md:hidden"
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <select
                onChange={(e) => handleNavigate(e.target.value)}
                className=" py-3 px-4 border rounded border-dark-purple w-full md:w-[300px] outline-none appearance-none relative"
              >
                {nodes?.map((node, i, arr) => (
                  <option value={node?.uri}>{node?.title}</option>
                ))}
              </select>
              <span className={`custom-arrow absolute right-[7.5%] top-0 h-full flex items-center `}>
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.5919 12C6.78686 11.9925 7.01273 11.9116 7.15441 11.7784L12.3363 6.86923C12.6762 6.58264 12.6687 6.0308 12.363 5.70906C12.0572 5.38625 11.5149 5.3607 11.2113 5.68455L7.40995 9.28134L7.40995 0.818196C7.40995 0.366497 7.04345 0 6.59175 0C6.14005 0 5.77355 0.366497 5.77355 0.818196L5.77355 9.28134L1.97222 5.68455C1.66858 5.36068 1.12418 5.38624 0.818422 5.70906C0.512663 6.0308 0.510535 6.59012 0.847188 6.86923L6.0291 11.7784C6.18783 11.9276 6.3735 11.9979 6.5919 12Z"
                    fill="black"
                  />
                </svg>
              </span>
            </form>

            {/* content */}
            <div className="w-full md:w-9/12">
              <div className="flex flex-col">
                <h3 className="hidden text-h3 md:block">{wpPage?.title}</h3>
                <div className="flex items-center mt-[9px] md:mt-[26px]">
                  <Icon type={IconType.Clock} />
                  <p className="ml-4 text-blue">Last Updated: {moment(wpPage?.template?.templateLegal?.lastUpdated, 'DD/MM/YYYY').format("MMMM DD, YYYY")} </p>
                </div>
                <div className="max-w-2xl mt-4 prose md:mt-12" dangerouslySetInnerHTML={{ __html: wpPage?.content }} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query Legal($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
      template {
        ... on WpTemplate_Legal {
          templateName
          templateLegal {
            lastUpdated
          }
        }
      }
      content
    }
    wp {
      ...GeneratedWp
    }
  }
`;
